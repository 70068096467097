const wx = require('weixin-js-sdk')
import { Dialog, Toast } from 'vant'
export default {
  data() {
    return {
      wxConfigData: {},
    }
  },
  methods: {
    wechatShare(info) {
      // 判断苹果手机
      let _url = ''
      if (window.__wxjs_is_wkwebview === true) {
        _url = window.location.href.split('#')[0] || window.location.href
      } else {
        _url = window.location.href
      }
      // 访问后台接口获取微信参数
      this.$axios
        .get(`/course/front/permit/wechat/jsConfig?url=${encodeURIComponent(_url)}`)
        .then((res) => {
          if (res.data.status === 1) {
            this.wxConfigData = res.data.data
            wx.config({
              debug: false,
              appId: res.data.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.data.signature, // 必填，签名，见附录1
              jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'hideAllNonBaseMenuItem'], // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
            })
          } else {
            Toast.fail(res.data.message)
          }
        })
        .catch((e) => {
          console.warn(e)
          return e
        })
      if (info && !info.disabled) {
        wx.ready(() => {
          const share_title = info.title
          const share_desc = info.desc
          let share_link = info.link
          const share_img = process.env.VUE_APP_URL + '/shareLogo.jpg'

          let hashBefore = share_link.split('#')[0]
          let path = share_link.split('#')[1]
          let linkUrl = ''
          // 自定义链接
          if (path.indexOf('?') > -1) {
            path = path + '&isShare=true'
          } else {
            path = path + '?isShare=true'
          }
          if (info.link.indexOf('index/news/newsDetail') !== -1) {
            linkUrl = hashBefore.includes('?') ? `${hashBefore}&shareUrl=${encodeURIComponent(encodeURIComponent(path))}` : `${hashBefore}?shareUrl=${encodeURIComponent(encodeURIComponent(path))}`
          } else {
            linkUrl = hashBefore.includes('?') ? `${hashBefore}&shareUrl=${encodeURIComponent(path)}` : `${hashBefore}?shareUrl=${encodeURIComponent(path)}`
          }
          wx.showOptionMenu()
          wx.onMenuShareTimeline({
            title: share_title, // 分享标题
            link: linkUrl, // 分享链接
            imgUrl: share_img, // 分享图标
            success: function() {
              // Toast('已成功分享到朋友圈')
            },
            cancel: function() {
              Toast('已取消分享')
            },
          })
          wx.onMenuShareAppMessage({
            title: share_title, // 分享标题
            desc: share_desc, // 分享描述
            link: linkUrl, // 分享链接
            imgUrl: share_img, // 分享图标
            success: function() {
              // Toast('已成功分享给您的朋友')
            },
            cancel: function() {
              Toast('已取消分享')
            },
          })
        })
      } else {
        wx.hideAllNonBaseMenuItem()
      }
    },
    wxReadyByIosVideoAutoPlay(player) {
      let res = this.wxConfigData.appid ? this.wxConfigData : {}
      wx.config({
        debug: false,
        appId: res.appid, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.noncestr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名，见附录1
        jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'hideAllNonBaseMenuItem'], // 必填，需要使用的 JS 接口列表，所有JS接口列表见附录2
      })
      wx.ready(() => {
        if (player) {
          player.play()
        }
      })
    },
    wxReadyByIosOrAndroidVideoAutoPlayByFaceAuth(player, isiOS) {
      if (this.itemData.isFaceAuth === 1) {
        player.pause()
        let info = JSON.parse(localStorage.userInfo)
        if (info.isChecked !== 1) {
          Dialog.confirm({
            title: '身份认证提示',
            message: '未进行身份认证，请先去身份认证',
          })
            .then(() => {
              this.$router.push({ path: `/user/userinfo/info` })
            })
            .catch(() => {
              return
            })
        } else {
          // 获取用户结果
          this.$axios
            .get(`/course/front/member/face/auth?type=Course&id=${this.id}&videoID=${this.lastWatchVideoID}`)
            .then((res) => {
              if (res.data.status === 1) {
                if (!res.data.data.bizToken && res.data.data.isSuccess === 1) {
                  if (isiOS) {
                    this.wxReadyByIosVideoAutoPlay(player)
                  } else {
                    player.play()
                  }
                } else {
                  window.location.href = res.data.data.url
                }
              } else {
                Toast.fail(res.data.message)
                return
              }
            })
            .catch(() => {
              Toast.fail('服务器开小差了，稍后重试')
              return
            })
        }
      } else {
        if (isiOS) {
          this.wxReadyByIosVideoAutoPlay(player)
        } else {
          player.play()
        }
      }
    },
  },
}
