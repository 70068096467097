import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vant from 'vant'
import less from 'less'
import 'vant/lib/index.css'
import './assets/js/flexible.js'
import axios from 'axios'
// import wx from 'weixin-js-sdk'
import { Toast } from 'vant'
import wxShare from '@/mixins/wechat'
import ZvNavBar from '@/components/ZvNavBar.vue'
import 'font-awesome/css/font-awesome.min.css'
import Vconsole from 'vconsole'

Vue.prototype.$axios = axios
Vue.prototype.$Toast = Toast
// Vue.prototype.$wx = wx
Vue.use(Vant)
Vue.config.productionTip = false
Vue.use(less)
Vue.component('ZvNavBar', ZvNavBar)

Vue.prototype.$eventHub = Vue.prototype.$eventHub || new Vue()
const vConsole = new Vconsole()
Vue.use(vConsole)

// axios的baseURL修改，本地开发修改.env.development这个文件中的VUE_APP_URL的值，开发环境修改.env.production这个文件的VUE_APP_URL的值
axios.defaults.baseURL = process.env.VUE_APP_URL

window.axios = axios
Vue.mixin(wxShare)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
