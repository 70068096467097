import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/page/index/Index'
import TestVideo from '@/page/index/TestVideo'
import AliVideo from '@/page/index/AliVideo'
import Broadcast from '@/page/index/Broadcast'
import Register from '@/page/login/Register'
import indexRouter from './modules/index'
import allCoursesRouter from './modules/allCourses'
import userRouter from './modules/user'
import nzzoneRouter from './modules/nzzone'
import studyRouter from './modules/study'
// import notPriv from './modules/notPriv'
import axios from 'axios'
import $ from 'jquery'
// import { Toast, Dialog } from 'vant'
import { Toast } from 'vant'

// 解决导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

let firstInPage = true

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      title: '麓辰教育',
      keepAlive: true,
    },
  },
  {
    path: '/index/demoVideo',
    name: 'testVideo',
    component: TestVideo,
  },
  {
    path: '/index/aliVideo/:id',
    name: 'aliVideo',
    component: AliVideo,
  },
  {
    path: '/broadcast',
    name: 'broadcast',
    component: Broadcast,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  ...indexRouter,
  ...allCoursesRouter,
  ...userRouter,
  ...nzzoneRouter,
  ...studyRouter,
  // ...notPriv,
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
})

if (!is_weixn()) {
  // 开发环境请放开这段注释，并填入正确的token值，cookie和userInfo ！！！！！！！！切记
  localStorage.token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJlY2hpc2FuIiwic3ViIjoiV0VCLTE4MzczMTU2OTI2X000VUEiLCJpYXQiOjE3MzQwNTY5MDEsImV4cCI6MTczNDA3NDkwMX0.y6ekZ7iPbQ0XNd_Nfw2CmzoAws0nEEzS-Z-er9YpQWO5txEVktHs09xTftRmGHkdC1CSUkTCoEFpRqbfsc5P7g'
  document.cookie = 'lcjykg-front-token=' + localStorage.token.replace('Bearer+', '')
  localStorage.userInfo =
    '{"ID":26408,"avatar":"https://thirdwx.qlogo.cn/mmopen/vi_32/maGUG7I6mxLTqTS1Eiar0wbZcMiagc31a9Nia3BCutlNC0ASuP9qGhiaaFw8cHN9VefT4n3Ne0qGZgmrmIXkYH6qGQ/132","consummate":false,"enterpriseID":1,"enterpriseInnercode":"0001","enterpriseName":"泽元软件","enterpriseShorthand":"泽元专区","expire":"18000","idCard":"431321199608220117","isChecked":1,"isFrontPage":1,"isVip":1,"mobile":"18373156926","parentEnterpriseID":0,"parentEnterpriseInnercode":null,"parentEnterpriseName":null,"questionDimensionID":3,"realName":"舒睆","redirectURI":null,"token":"Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJlY2hpc2FuIiwic3ViIjoiV0VCLTE4MzczMTU2OTI2X000VUEiLCJpYXQiOjE3MzQwNTY5MDEsImV4cCI6MTczNDA3NDkwMX0.y6ekZ7iPbQ0XNd_Nfw2CmzoAws0nEEzS-Z-er9YpQWO5txEVktHs09xTftRmGHkdC1CSUkTCoEFpRqbfsc5P7g","userName":"18373156926_M4UA","userType":"99","zsflDimensionID":1,"@Class":"com.zving.member.ui.model.LoginInfo"}'
  // document.cookie = 'lcjykg-front-userInfo=' + encodeURIComponent(localStorage.userInfo)
}

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.token
    if (token && config.url.indexOf('/course/front/permit/wechat') === -1) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = token.replace('Bearer+', 'Bearer ') //请求头加上token
    }
    if (config.url.indexOf('/index.json') !== -1) {
      config.url = config.url + '?' + new Date().getTime()
    } else if (config.url.lastIndexOf('.json') !== -1) {
      config.url = config.url + '?' + new Date().getTime()
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  (response) => {
    if (response.data && (response.data.loginExpired === 1 || response.data.loginExpired === '1')) {
      clearLocalStorage()
      if (firstInPage) {
        step2().then((url) => {
          firstInPage = false
          window.location.href = url
        })
      } else {
        step2().then((url) => {
          // Dialog.alert({
          //   message: '登录已失效，可能在其他终端登录或长时间未操作页面，点击确认自动重新登录',
          // }).then(() => {
          //   window.location.href = url
          // })
          window.location.href = url
        })
      }
    } else {
      return response
    }
  },
  (err) => {
    return Promise.reject(err)
  }
)

// 路由拦截器（开发环境手动将token放入localStorage中）
router.beforeEach(async (to, from, next) => {
  window.document.title = '\u200E'
  if (to.name === 'index') {
    window.document.title = '云上麓辰'
  }
  console.log('1111')
  if (process.env.NODE_ENV === 'production') {
    if (!is_weixn()) {
      // if (to.path !== '/notPriv/index') {
      //   next({
      //     path: 'notPriv/index',
      //   })
      // } else {
      //   next()
      // }
      next()
    }
  }
  console.log('2222')
  if (from.path === '/allCourses/examinationpaper' && to.path === '/study' && !to.query.formpath) {
    next({
      path: '/study?formpath=examinationpaper',
    })
  }
  if (from.meta.keepAlive) {
    console.log('3333')
    from.meta.savedPosition = $(document).scrollTop()
  }
  if (!localStorage.token && !getCookie('lcjykg-front-userInfo')) {
    console.log('4444', localStorage.token, getCookie('lcjykg-front-userInfo'))
    let url = await step2()
    window.location.href = url
  } else if (localStorage.token) {
    console.log('5555', localStorage.token)
    if (process.env.NODE_ENV === 'production') {
      console.log('6666')
      await isLogin()
    }

    document.cookie = 'lcjykg-front-token=' + localStorage.token.replace('Bearer+', '').replace('Bearer ', '')
    if (!JSON.parse(localStorage.userInfo).mobile) {
      if (to.path !== '/user/userinfo/changephone') {
        next({
          path: '/user/userinfo/changephone?title=完善手机号码',
        })
      } else {
        next()
      }
    }
    Vue.prototype.$eventHub.userInfo = JSON.parse(localStorage.userInfo)
    Vue.prototype.$eventHub.$emit('getUserInfo', JSON.parse(localStorage.userInfo))
    if (to.path === '/allCourses/index') {
      if (JSON.parse(localStorage.userInfo).isFrontPage === 0 && !to.query.isContinue) {
        next({
          path: '/nzzone',
        })
      } else {
        next()
      }
    } else if ((to.path === '/' || to.path === '/allCourses/index') && JSON.parse(localStorage.userInfo).isFrontPage === 0) {
      next({
        path: '/nzzone',
      })
    } else {
      next()
    }
  } else {
    let res = await step3()
    if (!JSON.parse(localStorage.userInfo).mobile) {
      if (to.path !== '/user/userinfo/changephone') {
        next({
          path: '/user/userinfo/changephone?title=完善手机号码',
        })
      } else {
        next()
      }
    }
    Vue.prototype.$eventHub.userInfo = res
    Vue.prototype.$eventHub.$emit('getUserInfo', res)
    if ((to.path === '/' || to.path === '/allCourses/index') && JSON.parse(localStorage.userInfo).isFrontPage === 0) {
      next({
        path: '/nzzone',
      })
    } else {
      next()
    }
  }
})

function is_weixn() {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i) == 'micromessenger' && ua.indexOf('wxwork') == -1) {
    return true
  } else {
    return false
  }
}

function step2() {
  if (is_weixn()) {
    return new Promise((resolve) => {
      let _url = window.location.href
      if (window.location.href.indexOf('?') > -1 && window.location.href.indexOf('from=singlemessage') === -1) {
        _url = _url + '&isLoged=true'
      } else {
        _url = _url + '?isLoged=true'
      }
      const url = encodeURIComponent(_url)
      axios
        .get(`/course/front/permit/wechat/auth?url=${url}`)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch(() => {
          resolve('')
        })
    })
  } else {
    // localStorage.token = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJlY2hpc2FuIiwic3ViIjoiV0VCLTE4MzczMTU2OTI2X000VUEiLCJpYXQiOjE3MzQwNTY5MDEsImV4cCI6MTczNDA3NDkwMX0.y6ekZ7iPbQ0XNd_Nfw2CmzoAws0nEEzS-Z-er9YpQWO5txEVktHs09xTftRmGHkdC1CSUkTCoEFpRqbfsc5P7g'
    // document.cookie = 'lcjykg-front-token=' + localStorage.token.replace('Bearer+', '')
    // localStorage.userInfo =
    // '{"ID":26408,"avatar":"https://thirdwx.qlogo.cn/mmopen/vi_32/maGUG7I6mxLTqTS1Eiar0wbZcMiagc31a9Nia3BCutlNC0ASuP9qGhiaaFw8cHN9VefT4n3Ne0qGZgmrmIXkYH6qGQ/132","consummate":false,"enterpriseID":1,"enterpriseInnercode":"0001","enterpriseName":"泽元软件","enterpriseShorthand":"泽元专区","expire":"18000","idCard":"431321199608220117","isChecked":1,"isFrontPage":1,"isVip":1,"mobile":"18373156926","parentEnterpriseID":0,"parentEnterpriseInnercode":null,"parentEnterpriseName":null,"questionDimensionID":3,"realName":"舒睆","redirectURI":null,"token":"Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJlY2hpc2FuIiwic3ViIjoiV0VCLTE4MzczMTU2OTI2X000VUEiLCJpYXQiOjE3MzQwNTY5MDEsImV4cCI6MTczNDA3NDkwMX0.y6ekZ7iPbQ0XNd_Nfw2CmzoAws0nEEzS-Z-er9YpQWO5txEVktHs09xTftRmGHkdC1CSUkTCoEFpRqbfsc5P7g","userName":"18373156926_M4UA","userType":"99","zsflDimensionID":1,"@Class":"com.zving.member.ui.model.LoginInfo"}'
    // document.cookie = 'lcjykg-front-userInfo=' + encodeURIComponent(localStorage.userInfo)
    return window.location.href
  }
}

async function step3() {
  // 从cookie中获取登录信息，解析后获取token
  let res = decodeURIComponent(getCookie('lcjykg-front-userInfo')) ? JSON.parse(decodeURIComponent(getCookie('lcjykg-front-userInfo'))) : ''
  if (res) {
    firstInPage = false
    localStorage.token = res.token
    document.cookie = 'lcjykg-front-token=' + res.token.replace('Bearer+', '').replace('Bearer ', '')
    localStorage.userInfo = JSON.stringify(res)
  } else {
    let url = await step2()
    window.location.href = url
    return
  }
  await isLogin()
  return new Promise((resolve) => {
    resolve(res)
  })
}

function getCookie(name) {
  let strcookie = document.cookie
  let arrcookie = strcookie.split('; ')
  for (let i = 0; i < arrcookie.length; i++) {
    let arr = arrcookie[i].split('=')
    if (arr[0] === name) {
      return arr[1]
    }
  }
  return ''
}

// 是否登录
function isLogin() {
  if (is_weixn()) {
    return new Promise((resolve) => {
      $.ajax({
        url: process.env.VUE_APP_SSOURL + '/sso/online',
        type: 'GET',
        dataType: 'jsonp',
        success: async (res) => {
          console.log('7777', res, localStorage.token)
          // sso未登录
          if (!res.Login) {
            doLogout()
          } else {
            if (!localStorage.token) {
              localStorage.userInfo = ''
              let url = await step2()
              window.location.href = url
            } else if (!localStorage.userInfo) {
              doLogout()
            } else {
              let userInfo = JSON.parse(localStorage.userInfo)
              if (res.AccessName !== userInfo.userName) {
                doLogout()
              }
              resolve(true)
            }
          }
        },
      })
    })
  } else {
    return true
  }
}
// 清空token，用户信息，调用注销接口
async function doLogout() {
  if (!localStorage.token) {
    let url = await step2()
    if (firstInPage) {
      firstInPage = false
      window.location.href = url
    }
    // Dialog.alert({
    //   message: '登录已失效，可能在其他终端登录或长时间未操作页面，点击确认自动重新登录',
    // }).then(() => {
    //   window.location.href = url
    // })
    window.location.href = url
  } else {
    axios({
      method: 'get',
      url: '/course/front/member/weblogout',
      headers: { Authorization: localStorage.token.replace('Bearer+', 'Bearer ') },
    })
      .then(async () => {
        localStorage.token = ''
        localStorage.userInfo = ''
        let url = await step2()
        if (firstInPage) {
          firstInPage = false
          window.location.href = url
        }
        // Dialog.alert({
        //   message: '登录已失效，可能在其他终端登录或长时间未操作页面，点击确认自动重新登录',
        // }).then(() => {
        //   window.location.href = url
        // })
        window.location.href = url
      })
      .catch(async () => {
        localStorage.token = ''
        localStorage.userInfo = ''
        let url = await step2()
        if (firstInPage) {
          firstInPage = false
          window.location.href = url
        }
        // Dialog.alert({
        //   message: '登录已失效，可能在其他终端登录或长时间未操作页面，点击确认自动重新登录',
        // }).then(() => {
        //   window.location.href = url
        // })
        window.location.href = url
      })
  }
}
// 保持sso在线(2分钟请求一次)
setInterval(() => {
  if (process.env.NODE_ENV === 'production') {
    isLogin()
  }
}, 120 * 1000)

// 刷新token
async function refreshToken() {
  let res = await isLogin()
  if (res) {
    axios({
      method: 'get',
      url: '/course/front/token/refresh/web',
      headers: { Authorization: localStorage.token.replace('Bearer+', 'Bearer ') },
    }).then((res) => {
      if (res.data.status === 1) {
        localStorage.token = res.data.data.token
        document.cookie = 'lcjykg-front-token=' + localStorage.token.replace('Bearer+', '').replace('Bearer ', '')
      } else {
        Toast.fail(res.data.message)
      }
    })
  }
}
// 接近三小时的时候刷新一次token
setInterval(() => {
  refreshToken()
}, 3590 * 1000 * 3)
function clearLocalStorage() {
  localStorage.userInfo = ''
  localStorage.token = ''
}
export default router
